import { Param } from "./interface";

export default function preferredLanguageChecker({ api_country_code, selected_country_code, available_country_codes_arr, default_country_code, debugMode }: Param) {

    const finder = (param: string | undefined, arr: string[]) => arr.find((elm) => elm === param);
    const preferred_browser_language = navigator.language.slice(0,2).toUpperCase();

   
    debugMode && console.log("%c PreferredLanguageChecker:", "color: yellow");

    if (!!api_country_code === true && !!selected_country_code === false) {

        let res = finder(api_country_code, available_country_codes_arr) ? api_country_code :
            finder(preferred_browser_language, available_country_codes_arr) ? preferred_browser_language :
                "default";

        debugMode && console.log("%c условие 1 :", "color: green", { api_country_code, selected_country_code, preferred_browser_language, res });

        return res

    } else if (!!api_country_code === false && !!selected_country_code === true) {
        let res = finder(selected_country_code, available_country_codes_arr) ? selected_country_code :
        "default";
        debugMode && console.log("%c условие 2 :", "color: green", { api_country_code, selected_country_code, default_country_code, res });

        return res

    } else {
        debugMode && console.log("%c условие 3 :", "color: red", { api_country_code, selected_country_code, default_country_code });

        return "default"
    }
}


