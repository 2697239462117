interface Prop {
    modalShow: boolean,
    modalTitle: string,
    modalBody: string,
    modalFooter: string,
    modalProps: {
        closeButton: boolean,
        size: string,
        backdrop: string,
        centered: boolean,
    },

    modalClassName: string,
    modalTemplate: string
};

export const modalDataDefault: Prop = {
    
    modalShow: false,
    modalTitle: "",
    modalBody: "",
    modalFooter: "",
    modalProps: {
        closeButton: true,
        size: "lg",
        backdrop: "static",
        centered: true,
    },
    modalClassName: "",
    modalTemplate: ""
}
