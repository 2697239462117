
export const logo = (

    <div className="logo_block">
        <div className="logo red-hat-black">
            <div className="anim">
                <span>{"Ferrari"}</span>
            </div>
        </div>

        <div className="logo_2 red-hat-black">
            <div className="anim_2">
                <span>{"Transporter"}</span>
            </div>
        </div>
    </div>
);
