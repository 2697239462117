import Template from "./Template/template";
import Navigation from "../Components/NavigationBar/navigation.jsx";
//import Faq from "../Components/Faq/faq.jsx";
import Introduction from "../Components/Introduction/Intro.jsx";
//import MODAL from "../Modal/modal";
import { useContext, Suspense, lazy } from "react";
import { LiveContext } from "../Context/context.ts";
import ChooseYourLanguage from "../Components/ChooseYourLanguage/choose_your_language";
//import Footer from "../Components/Footer/footer.tsx";
//import Advantages from "../Components/Profit/advantage.tsx"
//import Description from "../Components/Description/description";
//import Offer from "../Components/Offer/offer";
//import Collection from "../Components/Collection/collection";
//import Gallery from "../Components/Gallery/gallery";

const Description = lazy(() => import('../Components/Description/description'));
//const Offer = lazy(() => import('../Components/Offer/offer'));
const Gallery = lazy(() => import('../Components/Gallery/gallery'));
const Collection = lazy(() => import('../Components/Collection/collection'));
const Advantages = lazy(() => import('../Components/Profit/advantage.tsx'));
const Footer = lazy(() => import('../Components/Footer/footer.tsx'));
const Faq = lazy(() => import('../Components/Faq/faq.jsx'));
const MODAL = lazy(() => import('../Modal/modal'));
//const CustomModal = lazy(()=>import('../Modal/customModal.jsx'));

//import { flushSync } from 'react-dom';

//import Layout from "./Layout/layout";


export default function Page({ settings, ipInfo, route }) {
	const { modalData, setModalData, preferred_country_code } = useContext(LiveContext);


	return !!preferred_country_code && (
		<>
			<Template>
				{
					preferred_country_code === 'default' ?
						<ChooseYourLanguage />
						:
						<>
							<Navigation />
							<Introduction {...{ ipInfo, route }} />
							<Suspense>

								<Description />
								<div className="anchor" id="special-offer--section" style={{ paddingBottom: 10 }} name="special-offer--section"></div>
								{/* <Offer /> */}
								<Gallery />
								<Advantages />
								<div className="anchor" id="collection--section" name="collection--section"></div>
								<Collection />
								<div className="anchor" id="faq--section" name="faq--section"></div>
								<Faq />
								<Footer />
								{/* <CustomModal/> <-- activate when modal on start up */}

							</Suspense>
						</>
				}
			</Template>

			<MODAL
				show={modalData.modalShow}
				onHide={
					async () => {
						await setModalData({ ...modalData, modalShow: false });

						setTimeout(async () => {
							await setModalData({
								...modalData,
								modalTitle: "",
								modalBody: "",
								modalFooter: "",
								modalProps: {
									size: "",
									backdrop: false,
									centered: false,
									closeButton: false
								},
								modalClassName: "",
								modalTemplate: "",
								modalShow: false
							})

						}, 200)
					}
				}
			/>
		</>
	)
}

