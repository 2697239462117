import preferredLanguageChecker from '../Assets/preferredLanguageChecker.tsx';

export default async function fetchDataJSON({ api_country_code, selected_country_code, settings }, callback) {

    const { available_country_codes_arr, default_country_code, debugMode } = settings;
    const preferred_country_code = await preferredLanguageChecker(
        {
            api_country_code,
            selected_country_code,
            available_country_codes_arr,
            default_country_code,
            debugMode
        }
    );

    const pathToJSON = `./data/data_${(preferred_country_code === 'default'? default_country_code : preferred_country_code).toLowerCase()}.json`;

    available_country_codes_arr.find((elm) => elm === api_country_code) ?
        console.log('%c match found', 'color: lightgreen', { api_country_code }, available_country_codes_arr) :
        console.log('%c match NOT found', 'color: red', { api_country_code }, available_country_codes_arr);

    const response = await fetch(pathToJSON);

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    debugMode && console.log("%c JSON FILE: ", "color: #a648fd", pathToJSON, "STATUS ==>", response.status);
    const data = await response.json();
    
    callback({ ...data, preferred_country_code });;
}