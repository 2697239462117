import ferrari_logo from "../../media/logo/ferrari_horse.png";

export const Logos = ({ api_country_code }) => (

    <div className="logos">
        <div className="logo-ferrari">
            <img
                src={ferrari_logo}
                alt="Ferrari logo"
                width={"100%"}
            />
        </div>

        <div className="sticker--two-bolids">
            <img
                src={`images/bolids_st/2_bolida_${api_country_code === "PL" ? "PL" : api_country_code === "BG" ? "BG" : "HU"}.png`}
                alt=""
                width="100%"
            />
        </div>
    </div>
)