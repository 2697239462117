import NavDropdown from 'react-bootstrap/NavDropdown';
import { BG, PL, HU } from 'country-flag-icons/react/3x2';
import { useContext } from 'react';
// @ts-ignore //! error ts(2691)
import { LiveContext, LiveContextType } from "../../Context/context.ts";


export default function ChooseLanguageButton() {

    const { current_country_code, preferred_country_code } = useContext<LiveContextType>(LiveContext);
    const setCurrentLanguage = (selected_country_code: string) => {
        window.location.assign(`${window.location.origin}/?lang=${selected_country_code}`);
    };

    const classNames: string = 'h-16px mb-1 me-1';

    const languages = [
        { id: 1, selected_country_code: 'PL', language: 'Język polski', flag: <PL className={classNames} />, text: 'Zmiana języka' },
        { id: 2, selected_country_code: 'BG', language: 'Българския език', flag: <BG className={classNames} />, text: 'Смени езика' },
        { id: 3, selected_country_code: 'HU', language: 'Magyar', flag: <HU className={classNames} />, text: 'Nyelv módosítása' },
    ];

    const changeTitle = (arg: string): JSX.Element => {
        const f = languages.filter((elm) => elm.selected_country_code === arg)[0];
        return <><span>{f.text}</span>{f.flag}</>
    }

    return (preferred_country_code || current_country_code) && (
        <NavDropdown
            title={changeTitle(preferred_country_code)}
            id="collasible-nav-dropdown"
        >
            {
                languages.map(elm => (

                    <NavDropdown.Item
                        active={elm.selected_country_code === preferred_country_code ? true : false}
                        key={elm.id}
                        onClick={() => (preferred_country_code !== elm.selected_country_code) && setCurrentLanguage(elm.selected_country_code)}
                    >
                        <span className='me-2'>{elm.flag}</span>{elm.language}
                    </NavDropdown.Item>
                ))
            }

        </NavDropdown>
    );
}