import { useContext } from "react";
import { LiveContext } from "../../Context/context.ts";
import { Logos } from "./logos";
//import Picture from "../../Assets/picture.tsx";
import { logo } from "./logo/logo";
import { Container } from "react-bootstrap";
//import { IsWebp } from "../../Assets/testWebpSupport";
import { useWebpSupport } from "../../Assets/useWebpSupport";

const bgColor = 'rgb(255, 255, 255)';


export default function Introduction({ ipInfo, route }) {
    
    const isWebpSupported = useWebpSupport();
    const { description, api_country_code } = useContext(LiveContext);

    const title = description[0];
    

    return (

        <section id="introduction" style={{marginTop: 60}}>

            <Container fluid>
                
                <div
                    className="intro"
                    style={{ backgroundColor: !isWebpSupported ? bgColor : 'none' }}>

                        <div className="transporter-logo">
                            {logo}
                        </div>

                    <div className="title shadow-lg">
                        {title}
                    </div>

                    <Logos {...{api_country_code}}/>

                    <div className="wrapper">
                     
                        <picture>
                            <source srcSet="images/main/main_576c.jpg" media="(max-width: 575.98px)" />
                            <source srcSet="images/main/main_768c.jpg" media="(max-width: 767.98px)" />
                            <source srcSet="images/main/main_992c.jpg" media="(max-width: 991.98px)" />
                            <source srcSet="images/main/main_1200.jpg" media="(max-width: 1199.98px)" />
                            <img src="images/main/main_1200.jpg" alt="Ferrari Transporter" />
                        </picture>

                    </div>
                </div>

            </Container>
        </section>

    )
}
