import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useContext } from 'react';
import { LiveContext } from "../../Context/context.ts";
import { BiLogInCircle, BiCalendarCheck } from "react-icons/bi"
import PublicationsDatesTableTemplate from "../../Modal/modal_templates/publications-dates-table.tsx";
import ChooseLanguageButton from './chooseLangButton.tsx';
import FT_logo from "../../media/logo/FT_logo.png"



function Navigation({ settings, route }) {

	const { navigation, userLogin, setModalData, modalData, library, collection } = useContext(LiveContext);
	const navigationID = ['special-offer--section', 'collection--section', 'faq--section'];

	const handleModalOpenTable = async () => {
		await setModalData({
			...modalData,
			modalTitle: `${library[6]}`,
			modalTemplate: <PublicationsDatesTableTemplate {...{ collection, library }} />,
			modalClassname: 'publications-dates-table',
			modalProps: {
				size: "lg",
				backdrop: true,
				centered: true,
				closeButton: true
			},
			modalShow: true
		});
	};
		
	return (
		<Navbar collapseOnSelect expand="lg" bg="light" variant="light" className='shadow-lg' fixed='top'>
			<Container className='fs-6 text-black fw-bold'>

				<Navbar.Brand>
					<img
						src={FT_logo}
						alt=""
						height={"40px"}
						width={'100%'}
						className="d-inline-block d-lg-none align-top"
					/>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />

				

				<Navbar.Collapse id="responsive-navbar-nav">

					<Nav className="me-auto first">

						{
							navigation.map((elm, index) =>

								index > 0 &&
								<Nav.Link
									as="button"
									key={index}
									onClick={() => document.getElementById(navigationID[index]).scrollIntoView()}
									eventKey={index}
								>
									{elm}
								</Nav.Link>
							)
						}

					</Nav>

					<Nav className='second'>
						<ChooseLanguageButton {...{ settings, route }} />

						<div className="button-wrapper">
							<button
								type='button'
								className='open-modal-table ms-lg-3'
								onClick={() => handleModalOpenTable()}
							>
								<BiCalendarCheck size={'22px'} />
							</button>

							<button
								type='button'
								className='login ms-lg-2'
								onClick={() => window.open(`${userLogin}`)}
							>
								<BiLogInCircle size={'22px'} />
							</button>
						</div>

					</Nav>

				</Navbar.Collapse>

			</Container>
		</Navbar>
	);
}

export default Navigation;