const getGeoInfo = async (debugMode, callback) => {

    const IPAPI_ACCESS_KEY = process.env.REACT_APP_IPAPI_ACCESS_KEY;
    const IPGEOLOCATION_ACCESS_KEY = process.env.REACT_APP_IPGEOLOCATION_ACCESS_KEY;

    const ifErrorCounrtyCode = 'NL';

    try {
        const response = await fetch(`https://api.ipapi.com/api/check?access_key=${IPAPI_ACCESS_KEY}`);
        // const response = await fetch(`https://api.myip.com`, {
        //     method: 'GET',
        //     mode: 'cors',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //     },
        // });

        const data = await response.json();
        

        if (response.ok && data?.success !== false) {

            debugMode && console.log("%c ! ipapi.com ", "color: #8ceaa8", "==> ", response.status)
            debugMode && console.log("%c ! Fetching data: ", "color: #8ceaa8", "==> ", data ? data : "data is empty")

            callback({
                ip: data.ip,
                countryName: data?.country_name,
                country_code: data?.country_code,
                country_flag_emoji: data?.location?.country_flag_emoji,
                country_flag: data?.location?.country_flag,
                country_flag_emoji_unicode: data?.location?.country_flag_emoji_unicode,
                languages: data?.location?.languages,
                isLoaded: true
            });

            return !!data.country_code && data.country_code

        } else if(response.ok && !data.success) {
            throw new Error(`Response status: ${response.status}, ${data?.error?.info}`);

        } else {
            throw new Error(`Response status: ${response.status}, ${data?.error?.info}`);
        }

    } catch (error) {

        console.error("ipapi.com --->>", error)

        try {
            const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${IPGEOLOCATION_ACCESS_KEY}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });

            const data = await response.json();

            if (response.status === 200) {

                debugMode && console.log("%c ! ipgeolocation.io ", "color: #8ceaa8", "==> ", response.status)
                debugMode && console.log("%c ! Fetching data: ", "color: #8ceaa8", "==> ", data ? data : "data is empty");

                callback({
                    ip: data.ip,
                    countryName: data?.country_name,
                    country_code: data?.country_code2,
                    country_flag_emoji: null,
                    country_flag: data?.country_flag,
                    country_flag_emoji_unicode: null,
                    languages: data?.languages,
                    isLoaded: true
                });

                return !!data.country_code2 && data.country_code2

            } else {

                throw new Error('Server not respond or status !== 200');
            }

        } catch (error) {

            console.error("ipgeolocation.io --->>", error);
            console.log("%c !Set default region --> api not response or response status !== 200", "color: orange")
            return ifErrorCounrtyCode
        }
    }
}

export default getGeoInfo