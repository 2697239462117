import { useState, useEffect, useRef } from 'react';
import fetchDataJSON from './API/fetchDataJSON';
import getGeoInfo from './API/getGeoInfo';
import CheckLocationPath from "./Assets/checkLocationPath.tsx"
import './App.css';
import { LiveContext } from './Context/context.ts';
import Page from './Page/page';
import { Route, Routes } from 'react-router-dom';
import { modalDataDefault } from "./Modal/modalDataDefault.tsx";
import TagManager from 'react-gtm-module';

//import ServerFetch from './TestServerFetch/ServerFetch';

const tagManagerArgs_PL = {
    gtmId: 'GTM-MM5SBPH'
};

const tagManagerArgs_BG = {
    gtmId: 'GTM-5Q4VL9F'
};

const tagManagerArgs_HU = {
  //gtmId: 'GTM-MPTGVMC'
    gtmId: 'GTM-M23L3ZND'
};

const defaultSettings = {
    debugMode: false,
    available_country_codes_arr: ["PL", "BG", "HU"],
    default_country_code: "PL",
};

function App() {

    defaultSettings.debugMode && console.log('%c APP RENDER ', 'color: yellow');
    //ServerFetch();
    
    const [settings, setSettings] = useState(defaultSettings);
    const [modalData, setModalData] = useState(modalDataDefault);
    
    const [ipInfo, setIpInfo] = useState({
        isLoaded: false
    });

    const calledOnce = useRef(false);
    const check_location_path = CheckLocationPath(defaultSettings);

    useEffect(() => {

        if (!calledOnce.current && !check_location_path()) {
            settings.debugMode && console.log('useEffect run - condition #1');

            getGeoInfo(settings.debugMode, (res) => { setIpInfo(ipInfo => ({ ...ipInfo, ...res })) })
                .then((api_country_code) => {
                    
                    document.documentElement.lang = api_country_code.toLowerCase();

                    api_country_code === "PL" ?
                        TagManager.initialize(tagManagerArgs_PL) :
                        api_country_code === "BG" ? TagManager.initialize(tagManagerArgs_BG) : api_country_code === "HU" && TagManager.initialize(tagManagerArgs_HU);

                    fetchDataJSON(
                        { api_country_code, settings },
                        (fetchingData) => {
                            setSettings(settings => (
                                {
                                    ...settings,
                                    ...fetchingData,
                                    api_country_code: api_country_code,
                                    
                                }
                            ))
                        }

                    ).catch(err => console.error(err))
                    
                }).catch(err => console.error(err));;
                

        } else if (!calledOnce.current && !!check_location_path()) {
            settings.debugMode && console.log('useEffect run - condition #2');
            fetchDataJSON(
                { api_country_code: check_location_path(), settings },
                (fetchingData) => {
                    setSettings(settings => (
                        {
                            ...settings,
                            ...fetchingData,
                            api_country_code: check_location_path(),
                        }
                    ));
                
                    document.documentElement.lang = check_location_path().toLowerCase();
                    

                    check_location_path() === "PL" ?
                        TagManager.initialize(tagManagerArgs_PL) :
                        check_location_path() === "BG" ? TagManager.initialize(tagManagerArgs_BG) : check_location_path() === "HU" && TagManager.initialize(tagManagerArgs_HU);
                }
            ).catch(console.error);;
        }

        calledOnce.current = true;
        
    }, [settings, ipInfo.debugMode, check_location_path]);

    defaultSettings.debugMode && console.log('%c CURRENT SETTINGS:', 'color: orange', settings);

    return !!settings && (
       
        <LiveContext.Provider value={{ ...settings, updateSettings: setSettings, modalData, setModalData }}>
            <Routes>
                {
                    settings.preferred_country_code &&
                    <Route path='/' element={<Page  />} />
                }

                <Route
                    path='*'
                    element={<Page {...{ settings, ipInfo, route: "404" }} />}
                />
            </Routes>
        </LiveContext.Provider>
    
    );
}

export default App;
