import { useState, useEffect } from 'react';

export const useWebpSupport = () => {
  const [webpSupported, setWebpSupported] = useState(false);

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setWebpSupported(img.width > 0 && img.height > 0);
    };

    img.onerror = () => {
      setWebpSupported(false);
    };

    img.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  }, []);

  return webpSupported;
};