
import { createContext} from "react";

// declare interface  LiveContextType {

//     current_country_code: String | null,
//     preferred_country_code: String | null

//     modalData: {
//         modalTitle: String | JSX.Element,
//         modalBody: String | JSX.Element,
//         modalFooter: String | JSX.Element,
//         modalProps: {
//             size: String,
//             backdrop: Boolean,
//             centered: Boolean,
//             closeButton: Boolean
//         },
//         modalClassName: String,
//         modalTemplate: String
//     },

//     footer: {
//         local: String[],
//         address: String,
//         license: String,
//         copyright: String
//     },

//     advantahe: String[]
// }

const defaultValue = {

    current_country_code: null,
    preferred_country_code: null,

    modalData: {
        modalTitle: "",
        modalBody: "",
        modalFooter: "",
        modalProps: {
            size: "",
            backdrop: false,
            centered: false,
            closeButton: false
        },

        modalClassName: "",
        modalTemplate: ""
    },

    footer: {
        local: [],
        address: "",
        license: "Produced under license of Ferrari S.p.A. The name FERRARI, the PRANCING HORSE device, all associated logos and distinctive designs are property of Ferrari S.p.A. The body designs of the Ferrari cars are protected as Ferrari S.p.A. property under design, trademark and trade dress regulations.",
        copyright: "",
        contact: [],
        facebookLink: ""
    },

    advantage: [],

    links: {
        privacy: "",
        terms: "",
        viewExample: "",
    },
    
    userInformation: ""
}

export const LiveContext = createContext<LiveContextType>(defaultValue)

