import { Container } from "react-bootstrap";
import { useContext, useEffect, useRef, useMemo } from "react";
import {LiveContext} from "../../Context/context.ts";

export default function ChooseYourLanguage() {

    const { modalData, setModalData } = useContext(LiveContext);
    const calledOnce = useRef(true);
    //const [modalTitle, modalBody, modalFooter] = _modalData_;

    const changeRegion = "Unfortunately, this site is not available for your region. Please select another region. <br/>Niestety ta witryna nie jest dostępna w Twoim regionie. Wybierz inny region. <br/> За съжаление, този сайт не е достъпен за вашия регион. Моля, изберете друг регион. <br/>Sajnos ez az oldal az Ön régiójában nem elérhető. Kérjük, válasszon egy másik régiót."


    const newData = useMemo(() => {
        return {
            modalTitle: changeRegion,
            modalBody: "",
            modalFooter: "",
            modalClassName: "choose_your_language_modal",
            modalTemplate: "choose_language_modal_template",
            modalProps: {
                size: "md",
                backdrop:"static",
                centered: true,
                closeButton: false
            }
        }

    }, [])

    useEffect(() => {

        if (calledOnce.current) {

            setTimeout(() => {
                setModalData({ ...modalData, ...newData, modalShow: true });
            }, 700);
        };

        calledOnce.current = false;

    }, [modalData, setModalData, newData])

    return (
        <Container
            fluid
            className=""
            style={{ height: "100vh" }}
            id="choose_your_language"
        >
        </Container>
    )
}