import { useCallback } from "react";


export default function CheckLocationPath({ available_country_codes_arr, debugMode }: {available_country_codes_arr: string[], debugMode: boolean}) {

    return useCallback(() => {
       
        const urlParams = new URL(window.location.toLocaleString()).searchParams;
        const location = urlParams.get('lang');

        const isPresent = available_country_codes_arr.find((elm: string) => elm === location)

        if (!!isPresent ) {

            debugMode && console.log("CheckLocation result 1 - location present:", location);
            return location

        } else if (!!!isPresent ) {

            debugMode && console.log("CheckLocation result 2 - 404 location:", location);
            return null

        } else return null

    }, [available_country_codes_arr, debugMode]);
}

