import { motion, useScroll } from "framer-motion";
import { useContext } from "react";
import { LiveContext } from "../../Context/context.ts";

export default function Template({ children }) {

    const { preferred_country_code } = useContext(LiveContext);

    const { scrollYProgress } = useScroll();

    return (
        <main
            id="transporter_template"
            className="bg-light-gray position-relative w-100 h-100"
        >
            {preferred_country_code !== 'default' &&
                <motion.div
                    className="progress-bar d-none d-lg-block"
                    style={{ scaleX: scrollYProgress }}
                />
            }

            {children}

        </main>
    )
}