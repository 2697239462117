import { createRoot } from 'react-dom/client';
import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<Router>

	<App />
		
	

			

		</Router>
	</React.StrictMode>

);